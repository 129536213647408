// Navigation bar hiding
const navbar = document.getElementById("navbar");

const navContainer = document.getElementById("mainNav");
const landing = document.getElementById("home");

const logo = document.querySelector("#home .landing img");

// nav bar class depending if landing is visible
function updateNavClass() {
  //   console.log(landing.clientHeight);
  if (window.innerWidth > 639 && window.scrollY > landing.clientHeight - 80) {
    navContainer.classList.add("dark");
  } else {
    navContainer.classList.remove("dark");
    logo.style.opacity =
      1 - (((100 / landing.clientHeight) * window.scrollY) / 100) * 2;
  }
}

function sendMessage() {
  // const name = document.querySelector('#contact-form #cName');
  // const email = document.querySelector('#contact-form #cEmail');
  // const message = document.querySelector('#contact-form #cMessage');

  var post_url = "forms/submit.php";
  var request_method = "POST";
  var form_data = $("#contact-form").serialize();
  const countryData = window.iti.getSelectedCountryData();
  const fullTel = window.iti.getNumber();

  form_data = `${form_data}&cCode=${countryData.iso2}&code=${
    countryData.dialCode
  }&country=${encodeURI(countryData.name)}&fulltel=${encodeURI(fullTel)}`;

  $.ajax({
    url: post_url,
    type: request_method,
    data: form_data,
    statusCode: {
      400: function (responseObject, textStatus, jqXHR) {
        $("#submit-errors").text("Sending failed. Please try again!");
        $("#submit-errors").addClass("block");
      },
    },
  }).done(function (response) {
    //
    $("input, textarea, button, select", "#contact-form").prop(
      "disabled",
      true
    );
    $("#submit-errors").text("Message sent. Thank you!");

    $("#submit-errors").addClass("block");
  });
  return false;
}

$("#contact-form").on("submit", function (event) {
  event.preventDefault();
  sendMessage();
});

window.onresize = updateNavClass;

let prevScrollpos = window.scrollY;

window.onscroll = function () {
  const currentScrollPos = window.scrollY;
  updateNavClass();
  if (
    landing.clientHeight > currentScrollPos ||
    prevScrollpos > currentScrollPos
  ) {
    navbar.style.top = "0";
  } else {
    if (window.innerWidth > 639) {
      navbar.style.top = "-86px";
    }
  }
  prevScrollpos = currentScrollPos;
};

// hamburger

const hamburger = document.getElementById("hamburger");
let menuOpen = false;

const navList = document.querySelector("nav ul");

function updateNav() {
  if (menuOpen) {
    document.body.classList.add("no-scroll");
    navContainer.classList.add("bottom-0", "overflow-auto", "sm:bottom-auto");

    navList.classList.remove("hidden");
    navList.classList.add("flex");
  } else {
    document.body.classList.remove("no-scroll");
    navContainer.classList.remove(
      "bottom-0",
      "overflow-auto",
      "sm:bottom-auto"
    );
    navList.classList.add("hidden");
    navList.classList.remove("flex");
  }

  $("#hamburger").toggleClass("is-active");
}

document.querySelectorAll(".mainNavLink").forEach((l) =>
  l.addEventListener("click", (e) => {
    menuOpen = false;
    updateNav();
  })
);

$("#hamburger").on("click", () => {
  menuOpen = !menuOpen;
  updateNav();
});
